import React, {ReactElement} from 'react';
import Props from './typings';
import StyledActionButton from './styles';
import Icon from '../../../atoms/Icon/Icon';

const ActionButton = ({
  active = false,
  title,
  icon,
  onClick,
  disabled,
  wide,
  children,
}: Props): ReactElement => {
  return (
    <StyledActionButton
      title={title ? title : children}
      onClick={onClick}
      aria-disabled={disabled}
      $active={active}
      $wide={wide}
      $disabled={disabled}
      $text={children}
    >
      {icon && <Icon type={icon} fontSize={16} />}
      {children}
    </StyledActionButton>
  );
};

export default ActionButton;
