import {css} from 'styled-components';

const outlineHeading = css`
  font-size: 0;
  color: transparent;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
`;
export default {outlineHeading};
