'use client';
import styled, {css} from 'styled-components';
import {StyledProps} from './typings';
import colors from '../../../theme/constants/colors';
import button from '../../../theme/constants/button';
import pxToRem from '../../../theme/helpers/pxToRem';

const textStroke = (width, color) => css`
  text-stroke: ${width}px ${color};
  -webkit-text-stroke: ${width}px ${color};
`;

const textStrokeColor = (color) => css`
  text-stroke-color: ${color};
  -webkit-text-stroke-color: ${color};
`;

const btnTheme = (active) =>
  active
    ? {
        color: colors.red100,
        iconColor: colors.red100,
        iconColorDisabled: colors.grey100,
        iconStroke: 'transparent',
        iconStrokeDisabled: 'transparent',
        iconStrokeHovered: 'transparent',
        fontWeight: 'bold',
      }
    : {
        color: colors.darkBlue100,
        iconColor: 'transparent',
        iconColorDisabled: 'transparent',
        iconStroke: colors.darkBlue100,
        iconStrokeDisabled: colors.grey100,
        iconStrokeHovered: colors.red100,
        fontWeight: 'normal',
      };

const StyledActionButton = styled.button<StyledProps>`
  ${(props) => css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: ${btnTheme(props.$active).fontWeight};
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    height: ${button.height}px;
    min-width: ${button.height}px;
    padding: ${pxToRem(button.paddingY)} ${props.$text ? pxToRem(button.paddingX) : 0};
    font-size: ${pxToRem(button.fontSize)};
    border: 1px solid ${btnTheme(props.$active).color};
    border-radius: ${button.height}px;
    color: ${btnTheme(props.$active).color};
    background: transparent;

    [class^='icon-'],
    [class*=' icon-'] {
      margin-right: ${props.$text ? pxToRem(10) : 0};
      ${textStroke(1, btnTheme(props.$active).iconStroke)}
      color: ${btnTheme(props.$active).iconColor};
    }

    &:hover {
      border-color: ${colors.red100};
      color: ${colors.red100};

      [class^='icon-'],
      [class*=' icon-'] {
        ${textStrokeColor(btnTheme(props.$active).iconStrokeHovered)};
      }
    }

    &:active {
      border-color: ${colors.red100};
      color: ${colors.red100};

      [class^='icon-'],
      [class*=' icon-'] {
        color: ${colors.red100};
      }
    }

    &:focus {
      outline: none;
    }

    // disabled state
    ${props.$disabled &&
    css`
      color: ${colors.grey100};
      border-color: ${colors.grey100};
      pointer-events: none;

      [class^='icon-'],
      [class*=' icon-'] {
        color: ${btnTheme(props.$active).iconColorDisabled};
        ${textStrokeColor(btnTheme(props.$active).iconStrokeDisabled)};
      }
    `}

    // wide button
    ${props.$wide &&
    css`
      width: 100%;
    `}
  `}
`;

export default StyledActionButton;
