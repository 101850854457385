'use client';
import styled from 'styled-components';
import pxToRem from '../../theme/helpers/pxToRem';
import {StyledProps} from './typings';

const iconCode = {
  alert: '\\e900',
  'arrow-dropdown-down': '\\e901',
  'arrow-dropdown-up': '\\e902',
  'arrow-left': '\\e903',
  'arrow-right': '\\e904',
  bell: '\\e905',
  block: '\\e906',
  bookmark: '\\e907',
  camera: '\\e908',
  card: '\\e909',
  cart: '\\e90a',
  'chevron-down': '\\e90b',
  'chevron-left': '\\e90c',
  'chevron-right': '\\e90d',
  'chevron-up': '\\e90e',
  'circle-arrow-left': '\\e90f',
  'circle-arrow-right': '\\e910',
  close: '\\e911',
  comment: '\\e912',
  'customer-service': '\\e913',
  dashboard: '\\e914',
  download: '\\e915',
  'ellipsis-horizontal': '\\e916',
  'ellipsis-vertical': '\\e917',
  'emoji-happy': '\\e918',
  'emoji-neutral': '\\e919',
  'emoji-sad': '\\e91a',
  filters: '\\e91b',
  flag: '\\e91c',
  'heart-outlined': '\\e91d',
  heart: '\\e91e',
  idea: '\\e91f',
  image: '\\e920',
  information: '\\e921',
  inroom: '\\e922',
  link: '\\e923',
  location: '\\e924',
  menu: '\\e925',
  minus: '\\e926',
  padlock: '\\e927',
  plus: '\\e928',
  'profile-outlined': '\\e929',
  profile: '\\e92a',
  question: '\\e92b',
  quote: '\\e92c',
  search: '\\e92d',
  send: '\\e92e',
  settings: '\\e92f',
  'star-half': '\\e930',
  'star-outline': '\\e931',
  star: '\\e932',
  'trolley-empty': '\\e933',
  'trolley-full': '\\e934',
  truck: '\\e935',
  facebook: '\\e936',
  whatsapp: '\\e937',
  pinterest: '\\e938',
  instagram: '\\e939',
  houzz: '\\e93a',
  twitter: '\\e93b',
  tag: '\\e93c',
  share: '\\e93d',
  tree: '\\e93e',
  'tree-outlined': '\\e93f',
  'limited-edition': '\\e940',
  'limited-edition-50': '\\e941',
  'limited-edition-100': '\\e942',
  edit: '\\e943',
  upload: '\\e944',
  counter: '\\e945',
  'young-tree': '\\e946',
} as const;

const StyledIcon = styled.span<StyledProps>`
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: var(--af-icons, 'AF-icons') !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: ${(props) => (props.$fontSize ? pxToRem(props.$fontSize) : 'inherit')};
  color: ${(props) => (props.$color ? props.$color : 'inherit')};

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    content: '${(props) => iconCode[props.$type]}';
    font-family: inherit;
  }
`;

export default StyledIcon;
