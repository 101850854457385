import pxToRem from '../helpers/pxToRem';

export const padding = {
  l: pxToRem(40),
  m: pxToRem(20),
  s: pxToRem(15),
} as const;

export const margin = {
  l: pxToRem(25),
  m: pxToRem(20),
  s: pxToRem(10),
};
