import axios from 'axios';
import constantsFactory from '@/utils/constants';
import {
  LOAD_SEARCH_AUTOCOMPLETE_REQUEST,
  LOAD_SEARCH_AUTOCOMPLETE_SUCCESS,
  LOAD_SEARCH_AUTOCOMPLETE_FAILURE,
} from '@/redux/actionTypes';

const {API} = constantsFactory();

export const getSearchAutocomplete = (
  keyWord,
  onSuccessCallbacks = [],
  onErrorCallbacks = [],
  cleanupCallbacks = [],
) => {
  return {
    types: [
      LOAD_SEARCH_AUTOCOMPLETE_REQUEST,
      LOAD_SEARCH_AUTOCOMPLETE_SUCCESS,
      LOAD_SEARCH_AUTOCOMPLETE_FAILURE,
    ],
    callAPI: () =>
      axios.get(`${process.env.HOST}${API.SEARCH_AUTOCOMPLETE}`, {
        params: {query: keyWord},
        headers: {Accept: 'application/json'},
        withCredentials: true,
      }),
    onSuccess: onSuccessCallbacks,
    onCleanup: cleanupCallbacks,
    onError: onErrorCallbacks,
  };
};
