import {css} from 'styled-components';
import colors from '../constants/colors';

const scrollbar = css`
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background: transparent;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${colors.red100};
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: transparent;
  }
`;

export default scrollbar;
